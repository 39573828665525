<template>

  <div class="row" ref="sessionTopicsForm">
    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="title"
                      rules="required"
                      name="The Title"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="title"
                                name="title"
                                fou
                                @keyup="generateSomeFields"
                                v-model="formData.title">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/session-topics/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import {Option, Select, Tooltip} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'



extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    PrimeUploader,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        title: "",
      },


    };
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.sessionTopicsForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Session Topic";
      this.getSessionTopic();
    } else {
      this.editMode = false;
      this.formTitle = "Add Session Topic";
      this.loader.hide();
    }
  },
  methods: {
    getSessionTopic() {
      let data = {
        'id': this.id,
      };
      this.axios.post("session-topics/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Session Topic Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      if (this.editMode === true) {
        request = this.axios.put("session-topics/update/" + this.id, this.formData);
        successMessage = "Session Topic Updated Successfully";
      } else {
        request = this.axios.post("session-topics/create", this.formData);
        successMessage = "Session Topic Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/session-topics/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    generateSomeFields() {
      let newUrl = '';
      if (this.formData.title) {
        newUrl = this.formData.title.replace(/\s+/g, "-").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "");
        newUrl = newUrl.replace(/([-]+)/g, "-");
      }
      this.formData.url = newUrl;
    },
  }
}
</script>
